// const moment = require('moment');
// import { gsap } from "gsap";
// import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Navigation, Pagination, Autoplay, EffectFade, Swiper } from 'swiper'
Swiper.use([Navigation, Pagination, Autoplay, EffectFade]);
// gsap.registerPlugin(ScrollTrigger);

export function index() {
  $(window).on('load', function () {
    $('.toppage__mainimage').addClass('is_active');
  })
  const interviewswiper = new Swiper('.toppage__interview .swiper', {
    loop: true,
    slidesPerView: "auto",
    centeredSlides: true,
    spaceBetween: 21,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination',
    },

    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      640: {
        spaceBetween: 52,
      }
    }
  });
}
