import { index } from "./components/index";
import { animation } from "./components/animation";

index();
animation();

$(function () {
  $('.nav__open').off('click').on('click', function () {
    console.log('a');
    $(this).toggleClass('close');
    $('.header__nav').toggleClass('fade');
    $('.nav__overlay').toggleClass('fade');
  });
  if ($('.faqpage__item').length) {
    $('.faqpage__item .question').off('click').on('click', function () {
      $(this).next().slideToggle();
      $(this).toggleClass('is_open');
    });

    var fqplist = $('.faqpage__list');
    $('input[name="categories"]').change(function () {
      var val = $(this).val();
      if ($(this).val() === "all") {
        $(".faqpage__list").show();
      } else {
        $(".faqpage__list").hide();
        fqplist
          .filter(
            function () {
              return ($(this).data('category') === val);
            }
          ).show();
      }
    })
  }
  window.onload = switchByWidth;
  window.onresize = switchByWidth;

  function switchByWidth() {
    if (window.matchMedia('(max-width: 768px)').matches) {
      // console.log('sp');
      $('.footer__nav__container p').off('click').on('click', function () {
        $(this).next().slideToggle();
        $(this).toggleClass('is_open');
        //$('.footer__nav__container p').off('click');
      });
    } else if (window.matchMedia('(min-width:768px)').matches) {
      // console.log('pc');
    }
  }
  if ($('#mw_wp_form_mw-wp-form-106.mw_wp_form_confirm').length) {
    $('.documentpage__single__body').hide();
  }
  if ($('#mw_wp_form_mw-wp-form-106.mw_wp_form_complete').length) {
    $('.documentpage__single__body').hide();
  }
});
$(window).on('load', function () {
  var url = $(location).attr('href');
  if (url.indexOf("#") != -1) {
    var anchor = url.split("#");
    var target = $('#' + anchor[anchor.length - 1]);
    if (target.length) {
      var pos = Math.floor(target.offset().top) - 120;
      $("html, body").animate({ scrollTop: pos }, 500);
    }
  }
});
