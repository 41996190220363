export function animation() {
  let fadeInTarget = document.querySelectorAll('.fade-in');
  window.addEventListener('scroll', () => {
    for (let i = 0; i < fadeInTarget.length; i++) {
      const rect = fadeInTarget[i].getBoundingClientRect().top;
      const scroll = window.pageYOffset || document.documentElement.scrollTop;
      const offset = rect + scroll;
      const windowHeight = window.innerHeight;
      if (scroll > offset - windowHeight + (windowHeight / 3)) {
        fadeInTarget[i].classList.add('scroll-in');
      }
    }
  });
}
